import { View, ViewProps } from "react-native";
import AppBrand from "./AppBrand";

export default function Tasking({ ...props }: ViewProps) {
	return (
		<View {...props}>
			<AppBrand style={{ marginBottom: 30 }} />

			<svg xmlns="http://www.w3.org/2000/svg"
				height="50px" viewBox="0 0 100 100"
				preserveAspectRatio="xMidYMid"
				style={{
					shapeRendering: "auto",
					alignSelf: 'center',
				}}>
				<circle cx="50" cy="50" r="32" strokeWidth="6" stroke="#6200ea" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
					<animateTransform attributeName="transform" type="rotate" dur="2.5s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
				</circle>

				<circle cx="50" cy="50" r="23" strokeWidth="6" stroke="#1976d2" strokeDasharray="36.12831551628262 36.12831551628262" strokeDashoffset="36.12831551628262" fill="none" strokeLinecap="round">
					<animateTransform attributeName="transform" type="rotate" dur="3s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
				</circle>
			</svg>
		</View>
	);
}