import { StyleSheet, View } from "react-native";
import Tasking from "../component/Tasking";
import { centerContent } from "../utils/commonStyles";

export default function TaskingUI() {
	return (
		<View style={styles.background}>
			<Tasking style={styles.tasking} />
		</View>
	);
}

const styles = StyleSheet.create({
	background: {
		height: "100vh", 
		width: "100vw",
		...centerContent,
	},
	
	tasking: {
		width: 300,
		alignSelf: 'center',
		backgroundColor: 'white',
	},
});