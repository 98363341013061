import { AuthCredential, OAuthCredential } from "firebase/auth";
import { anyPass, endsWith, equals, startsWith } from "ramda";
import { getQueryParams } from "./url";


export async function postCredential(credential: AuthCredential) {
	const { origin } = getQueryParams();

	// 🚔 check if origin is white listed
	const originUrl = new URL(origin);
	if (!isWhitelisted(originUrl.host)) return;

	const credentialJson = credential.toJSON();
	// support old firebase versions
	credentialJson["oauthAccessToken"] = (credential as OAuthCredential).accessToken; // shop-web maintainance

	function redirect() {
		const targetUrl = new URL(origin);
		targetUrl.searchParams.set('credential', JSON.stringify(credentialJson));
		window.location.href = targetUrl.href;
	}

	const needsRedirection = isFacebookApp() || !window.opener;
	if (needsRedirection)
		redirect();
	else { // post or, if no handled, redirect
		window.opener.postMessage(credentialJson, origin);
		// after 3 seconds without being closed, redirect to origin
		setTimeout(redirect, 3000);
	}
}

const isWhitelisted = anyPass([
	equals("shopinzon-pay.web.app"),
	endsWith(".shopinzon.com"),
	endsWith("dot-rcm55-bagshop.appspot.com"),
	// dev
	startsWith("localhost:"),
	startsWith("192.168."),
]);


function isFacebookApp() {
	const ua = navigator.userAgent || navigator.vendor || window["opera"];
	return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}