import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { invertObj, isNil } from "ramda";
import { Maybe } from "../types";


export const appProviders = {
	apple: 'apple.com',
	google: GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD,
	facebook: FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD,
	// password: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
	// email: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
} as const;


export type ProviderKey = keyof (typeof appProviders);
export type ProviderId = (typeof appProviders)[ProviderKey];
export type OAuthProviderId = typeof oAuthProviders[number];

// In order of preference
export const oAuthProviders = [appProviders.facebook, appProviders.google, appProviders.apple] as const;
export function isOAuthProviderId(input: any): input is OAuthProviderId {
	return oAuthProviders.includes(input);
}

const keys = invertObj(appProviders) as { [key in ProviderId]: ProviderKey };

const keysAndIds = {
	...appProviders,
	...keys,
};

export const appProvidersList = Object.values(appProviders);

export function isProviderMaintained(provider: any): provider is ProviderId | ProviderKey {
	return Boolean(keysAndIds[provider]);
}

export function toProviderId(provider: string | undefined): ProviderId | undefined {
	if (!isNil(provider))
		return appProviders[provider] || (keys[provider] ? provider : undefined);
}


export function toProviderKey(provider: string | undefined): ProviderKey | undefined {
	if (provider)
		return appProviders[provider] ? provider : keys[provider];
}



export function authProviderTypeOf(providerId: OAuthProviderId) {
	let authProviderType: typeof FacebookAuthProvider | typeof GoogleAuthProvider | typeof OAuthProvider;

	switch (providerId) {
		case appProviders.facebook:
			authProviderType = FacebookAuthProvider;
			break;

		case appProviders.google:
			authProviderType = GoogleAuthProvider;
			break;

		case appProviders.apple:
			authProviderType = OAuthProvider;
			break;
	}

	return authProviderType;
}

export function createAuthProviderFor(providerId: OAuthProviderId) {
	const Provider = authProviderTypeOf(providerId);
	const authProvider = new Provider(providerId);
	authProvider.addScope('email');
	if (providerId === appProviders.apple)
		authProvider.addScope('name');
	return authProvider;
}