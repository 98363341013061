
export function circle(size: number) {
	return {
		height: size,
		width: size,
		borderRadius: size / 2,
	};
}

export const centerContent = {
	alignItems: 'center',
	justifyContent: 'center',
} as const;

export const screenStyle = {
	...centerContent,
	minHeight: "100vh",
	width: "100vw",
	paddingVertical: 20,
} as const;

export function absolute(position: { top?: number, right?: number, bottom?: number, left?: number }) {
	return {
		position: "absolute",
		...position,
	};
}