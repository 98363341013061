import { Image, Pressable, PressableProps, Text } from "react-native";
import useStyle from "rn-tools/hook/useStyle";
import { appProviders } from "../../utils/auth/auth-providers";
import { circle } from "../../utils/commonStyles";

export default function ProviderButton({ provider, showTitle, ...props }: ProviderButtonProps) {
	const { logo, title, layoutStyle } = PROVIDERS_RES[provider] || {};

	props.style = useStyle(props.style, {
		flexDirection: 'row',
		height: 50,
		borderRadius: 50 / 2,
		padding: 10,
		borderWidth: 1,
		alignItems: 'center',
		...layoutStyle,
	});

	return (
		<Pressable {...props}>
			<Image
				source={logo}
				resizeMode="cover"
				style={{
					...circle(28),
				}} />


			{
				Boolean(showTitle) &&
				<Text style={{
					color: 'black',
					fontSize: 18,
					fontWeight: 'bold',
					minWidth: 200,
					marginLeft: 15,
				}}>
					{title}
				</Text>
			}
		</Pressable>
	);
}

export type ProviderButtonProps = PressableProps & {
	provider: string,
	showTitle?: boolean,
};

const PROVIDERS_RES = {
	[appProviders.apple]: {
		logo: require('./apple.png'),
		title: `Apple`,
		layoutStyle: {
			borderColor: "black",
		},
	},

	[appProviders.google]: {
		logo: require('./google.png'),
		title: `Google`,
		layoutStyle: {
			borderRightColor: '#4285F4',
			borderBottomColor: '#34A853',
			borderLeftColor: '#FBBC05',
			borderTopColor: '#EA4335',
		}
	},

	[appProviders.facebook]: {
		logo: require('./facebook.png'),
		title: `Facebook`,
		layoutStyle: {
			borderColor: "rgb(24, 119, 242)",
		}
	},
};

