import { StyleSheet, Text, View } from "react-native";
import { ProviderId } from "../utils/auth/auth-providers";
import { Collision } from "../utils/auth/authLinker";
import { screenStyle } from "../utils/commonStyles";
import ProvidersButtons from "./LoginUI/ProvidersButtons";

export default function CollisionUI({ collision, authenticate }: CollisionUIProps) {
	return (
		<View style={screenStyle}>
			<View style={styles.box}>
				<Text style={styles.message}>
					{`Votre compte shopinzon ${collision.email} est actuellement associé à un autre système de connexion. Connectez vous avec l'un des moyens suivants pour continuer.`}
				</Text>

				<ProvidersButtons
					providers={collision.providers}
					onProviderPress={authenticate}
					style={styles.buttons} />
			</View>
		</View>
	);
}


interface CollisionUIProps {
	collision: Collision,
	authenticate(providerId: ProviderId): void,
}

const styles = StyleSheet.create({
	box: {
		width: "100%",
		maxWidth: 400,
		padding: 15,
	},

	message: {
		textAlign: "center",
		marginBottom: 20,
	},

	buttons: {
		alignSelf: "stretch"
	}
});