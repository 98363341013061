import { User } from "firebase/auth";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import AppBrand from "../../component/AppBrand";
import colors from "../../res/colors";
import { ProviderId } from "../../utils/auth/auth-providers";
import { screenStyle } from "../../utils/commonStyles";
import ProvidersButtons from "./ProvidersButtons";

export default function LoginUI({ user, authenticate, reauthenticate }: UIProps) {
	return (
		<View style={screenStyle}>
			<Text style={styles.title}>
				{`Portail de connexion à Shopinzon`}
			</Text>

			<Image
				source={{ uri: user?.photoURL || require('../../res/user.png') }}
				resizeMode="cover"
				style={styles.picture}
			/>

			{
				user &&
				<>
					<Text style={styles.name}>
						{user.displayName}
					</Text>

					<Text style={styles.email}>
						{user.email}
					</Text>

					<Pressable
						onPress={reauthenticate}
						style={styles.reauthenticate}>
						<Text style={styles.reauthenticateText}>
							{`Utiliser ce compte`}
						</Text>
					</Pressable>
				</>
			}

			<View style={styles.separator} />

			<ProvidersButtons
				onProviderPress={authenticate}
				style={styles.buttons} />

			<AppBrand style={styles.brand} />
		</View>
	);
}

interface UIProps {
	user: User | undefined,
	authenticate(providerId: ProviderId): void,
	reauthenticate(): void,
}

const styles = StyleSheet.create({
	title: {
		fontWeight: 'bold',
		fontSize: 25,
		color: 'black',
		marginBottom: 40,
		textAlign: 'center',
	},

	picture: {
		alignSelf: 'center',
		height: 75,
		width: 75,
		borderRadius: 75 / 2,
		borderWidth: 3,
		borderColor: colors.main,
		marginBottom: 10,
	},

	name: {
		fontWeight: 'bold',
		fontSize: 18,
		textAlign: 'center'
	},

	email: {
		fontSize: 18,
		textAlign: 'center',
	},

	reauthenticate: { paddingVertical: 15 },
	reauthenticateText: {
		color: colors.main,
		fontWeight: 'bold',
		fontSize: 20,
		textAlign: 'center',
	},

	separator: {
		marginVertical: 20,
		height: 1,
		backgroundColor: "lightgray",
		width: '50%',
		maxWidth: 100,
	},

	buttons: {
		alignSelf: "stretch",
	},

	brand: {
		width: 150,
		alignSelf: 'center',
		marginTop: 100,
	},
});