import { getAuth, linkWithCredential, OAuthCredential, onAuthStateChanged } from "firebase/auth";
import { complement, compose, construct, evolve, filter, groupBy, isNil, map, pipe, propEq, sort, uniqBy } from "ramda";
import { ProviderId } from "./auth-providers";


export function saveCollision(collision: Collision) {
	const list = getCollisionsList();
	list.unshift(collision);
	setCollisionsList(list);
}

export interface Collision {
	credential: OAuthCredential,
	email: string,
	providers: ProviderId[],
	date: Date,
};

const KEY = "auth collisions";
function getCollisionsList(): Collision[] {
	let result: any[];

	try {
		result = localStorage[KEY] ? JSON.parse(localStorage[KEY]) : []
	}
	catch (error) {
		console.error(error);
		result = [];
	}

	return result.map(evolve({
		date: construct(Date),
		credential: OAuthCredential.fromJSON,
	})) as Collision[];
}

function setCollisionsList(collisions: Collision[]) {
	localStorage[KEY] = pipe(
		sort((c1: Collision, c2: Collision) => c2.date.valueOf() - c1.date.valueOf()),
		uniqBy(collision => `${collision.email}:${collision.credential.providerId}`),
		JSON.stringify,
	)(collisions);
}


// auto link user's credentials
onAuthStateChanged(getAuth(), user => {
	if (user) {
		const collisionsForThisUser: { [k in 'true' | 'false']?: Collision[] } = pipe(
			groupBy(compose(String, propEq('email', user.email))),
			map(filter(complement(isNil))) as any,
		)(getCollisionsList()) as any;
		if (collisionsForThisUser.true) {
			console.log({ collisionsForThisUser });
			collisionsForThisUser.true.forEach(collision => linkWithCredential(user, collision.credential));
			setCollisionsList(collisionsForThisUser.false || []);
		}
	}
});