import { Image, ImageProps } from "react-native"
import useStyle from "rn-tools/hook/useStyle"

export default function AppBrand({ ...props }: Omit<ImageProps, 'source'>) {
	props.style = useStyle(props.style, {
		aspectRatio: 626 / 120,
	});

	return (
		<Image
			resizeMode="contain"
			{...props}
			source={SOURCE}
		/>
	)
}

const SOURCE = { uri: "https://storage.googleapis.com/public-res/shopinzon-logos/logo-and-name-bright.png" };