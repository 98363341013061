import { StyleSheet, View, ViewProps } from "react-native";
import useResponsive from "rn-tools/hook/useResponsive";
import ProviderButton from "../../component/ProviderButton";
import { oAuthProviders, ProviderId } from "../../utils/auth/auth-providers";
import { centerContent } from "../../utils/commonStyles";

export default function ProvidersButtons({providers = oAuthProviders, onProviderPress, ...props }: ProvidersButtonsProps) {
	const [level, onLayout] = useResponsive(RESPONSIVE_LEVELS);
	const rootStyles: ViewProps["style"] = [
		styles.root,
		level === RESPONSIVE_LEVELS.mobile &&
		{ flexDirection: 'row' }
	];

	props.onLayout = onLayout;
	props.style = StyleSheet.compose(rootStyles, props.style);
	return (
		<View {...props}>
			{
				providers.map(providerId =>
					<ProviderButton
						key={providerId}
						showTitle={level > RESPONSIVE_LEVELS.mobile}
						provider={providerId}
						onPress={() => onProviderPress(providerId)}
						style={styles.button} />
				)
			}
		</View>
	)
}

interface ProvidersButtonsProps extends ViewProps {
	providers?: readonly ProviderId[],
	onProviderPress(providerId: ProviderId): void,
}


const RESPONSIVE_LEVELS = {
	mobile: 300,
} as const;


const styles = StyleSheet.create({
	root: centerContent,

	button: {
		margin: 10,
		maxWidth: '95%',
	},
});