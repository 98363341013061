import { AuthError, AuthErrorCodes, fetchSignInMethodsForEmail, getAuth, getRedirectResult, linkWithRedirect, OAuthProvider, reauthenticateWithRedirect, signInWithRedirect, User, UserCredential } from "firebase/auth";
import { prop } from "ramda";
import { useEffect } from 'react';
import { ScrollView } from 'react-native';
import { useQuery } from 'react-query';
import useUser from "rn-tools/hook/useUser";
import { authProviderTypeOf, createAuthProviderFor, isOAuthProviderId, OAuthProviderId, oAuthProviders, ProviderId, toProviderId } from './utils/auth/auth-providers';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postCredential } from './utils/postman';

import onceAuthReady from "rn-tools/utils/onceAuthReady";
import { useStateWithDeps } from "use-state-with-deps";
import CollisionUI from "./ui/CollisionUI";
import LoginUI from "./ui/LoginUI";
import TaskingUI from "./ui/TaskingUI";
import { Collision, saveCollision } from './utils/auth/authLinker';
import unwrap from './utils/string/unwrap';
import { getQueryParams } from './utils/url';


export default function App() {
	// --- Retrieve data
	const { data: collision, isLoading: checkingResult } = useQuery('authCollision', () => gettingAuthCollisionResult);

	const queryParams = getQueryParams();
	const email = queryParams.email;

	const providerParam = toProviderId(queryParams.provider || /* deprecated */ unwrap(queryParams.method));
	const provider = providerParam || (queryParams.provider === "auto" ? "auto" : undefined);

	// --- Compute initial state
	const user: User = useUser();
	const isUserEmail = Boolean(user && email == user?.email);
	const canUseUser = Boolean(user && (!email || isUserEmail));

	const noCollision = !checkingResult && !collision;
	const willReauthenticate = noCollision && canUseUser && (provider === "auto" || isUserEmail);
	const willAuthenticate = noCollision && provider && provider !== "auto";

	const [authenticating, setAuthenticating] = useStateWithDeps<boolean>(
		Boolean(willReauthenticate || willAuthenticate),
		[noCollision],
	);

	// --- actions
	function authenticate(providerId: ProviderId): void {
		setAuthenticating(true);
		const authProvider = createAuthProviderFor(providerId);
		signInWithRedirect(getAuth(), authProvider);
	}

	function reauthenticate() {
		if (!user) {
			console.error('No user to reauthenticate');
			return;
		};

		setAuthenticating(true);

		const userProviders = user.providerData
			.map<string>(prop('providerId'))
			.filter(isOAuthProviderId);

		// pass over oAuthProviders to find the 1st in order of preference
		const provider = oAuthProviders.find(preferedProvider => userProviders?.includes(preferedProvider));
		if (!provider) {
			setAuthenticating(false);
			console.error("No oAuth provider found for user: ", user.providerData);
			return;
		}

		reauthenticateWithRedirect(user, createAuthProviderFor(provider));
	}

	async function link(provider: OAuthProviderId) {
		const authProvider = createAuthProviderFor(provider);
		if (authProvider) {
			linkWithRedirect(user, authProvider);
		}
	}

	// --- run automatic (re)authentication
	useEffect(() => {
		if (willReauthenticate) reauthenticate();
		else if (willAuthenticate) authenticate(provider);
	}, [noCollision]);

	// ---
	const actions = { authenticate, reauthenticate, link } as const;
	const tasking = checkingResult || authenticating;

	return (
		<>
			<ScrollView
				style={{ flex: 1 }}
				contentContainerStyle={{ flexGrow: 1 }}>
				{
					tasking ?
						<TaskingUI /> :

						collision ?
							<CollisionUI collision={collision}
								{...actions} /> :

							<LoginUI
								user={canUseUser ? user : undefined}
								{...actions} />
				}
			</ScrollView>

			<ToastContainer hideProgressBar closeButton={false} />
		</>
	);
}


const gettingAuthCollisionResult: Promise<Collision | void> = onceAuthReady()
	.then(() => getRedirectResult(getAuth()))
	.then(async (result: UserCredential | null) => {
		console.log({ redirectResult: result });

		if (result?.user) {
			if (isOAuthProviderId(result.providerId)) {
				const Provider = authProviderTypeOf(result.providerId);
				const credential = Provider.credentialFromResult(result);
				if (credential) {
					await wait(); // let authLinker module time
					postCredential(credential)
						.catch(error => {
							console.log('Error posting credential:');
							console.error(error);
						});
				}
			}

			await new Promise(() => 0); // stop & prevent infinite loop
		}
	},
		async (error: AuthError) => {
			console.error(error);

			const credential = OAuthProvider.credentialFromError(error);
			if (error.code === AuthErrorCodes.NEED_CONFIRMATION && error.customData.email && credential) {
				const allUserProviders = await fetchSignInMethodsForEmail(getAuth(), error.customData.email);
				const providers = allUserProviders.filter(isOAuthProviderId);
				const collision = {
					email: error.customData.email,
					credential,
					providers,
					date: new Date,
				};

				saveCollision(collision);

				console.log({ collision });

				return collision;
			}
		});

const wait = (time = 1000) => new Promise(res => setTimeout(res, time));