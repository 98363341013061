
import { initializeApp } from 'firebase/app';
import * as app from 'firebase/app';
import * as auth from 'firebase/auth';
import version from './version';

console.log(version);


initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	appId: "1:607058134897:web:e0e10684c8a12f162ed8d0",
	authDomain: "auth.shopinzon.com",
});

window['firebase'] = { app, auth };
